import React, { useState } from "react";
import styles from "./styles/SolutionsPage.module.css";
import Image2 from "../assets/image2.png";
import Image3 from "../assets/image3.png";
import Image4 from "../assets/image4.png";
import Image5 from "../assets/image5.png";
import Icon from "../assets/Icon.svg";

const SolutionsPage: React.FC = () => {
  const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>({
    section1: true,
    section2: true,
    section3: true,
    section4: true,
  });

  const handleClick = (section: string) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <div className={styles.solutions}>
      <div className={styles.solutionsContainer}>
        <div className={styles.section}>
          <div
            className={`${styles.sectionBackground} ${
              openSections.section1 ? styles.sectionBackgroundOverlay : ""
            }`}
            style={{ backgroundImage: `url(${Image5})` }}
          ></div>
          {openSections.section1 ? (
            <div className={styles.sectionText}>
              <p>REMOTE OPERATIONS FOR THE MARINE INDUSTRY</p>
              <button
                className={styles.comingSoonButton}
                onClick={() => handleClick("section1")}
              >
                Read More...
              </button>
            </div>
          ) : (
            <div className={styles.sectionTextHidden}>
              <div className={styles.sectionTextHiddenTitle}>
                <h2>Remote</h2>
                <img
                  src={Icon}
                  alt=""
                  onClick={() => handleClick("section1")}
                />
              </div>
              <p>
                IMR (Inspection, Maintenance, and Repair) is essential for
                ensuring safety, compliance, and operational efficiency.
              </p>

              <p>
                Performing IMR remotely offers significant value. It enhances
                safety by reducing the need for personnel to work in hazardous
                environments, minimizing the risk of accidents.
              </p>

              <p>
                Remote IMR also improves operational efficiency through
                real-time monitoring and control, allowing for quick detection
                and resolution of issues. This leads to reduced downtime and
                maintenance costs, as problems can be addressed proactively
                before they escalate.
              </p>

              <p>
                Additionally, remote IMR supports environmental sustainability
                by lowering the carbon footprint associated with travel and
                on-site inspections.
              </p>

              <p>
                Overall, the ability to perform IMR remotely ensures safer, more
                efficient, and cost-effective operations.
              </p>

              <button className={styles.secondButton}>
                <a href="/contact">Contact us</a>
              </button>
            </div>
          )}
        </div>
        <div className={styles.sectionreverse}>
          <div
            className={`${styles.sectionBackground} ${
              openSections.section2 ? styles.sectionBackgroundOverlay : ""
            }`}
            style={{ backgroundImage: `url(${Image3})` }}
          ></div>
          {openSections.section2 ? (
            <div className={styles.sectionText}>
              <p>CONNECTIVITY LINKING YOU TO THE WORLD</p>
              <button
                className={styles.comingSoonButton}
                onClick={() => handleClick("section2")}
              >
                Read More...
              </button>
            </div>
          ) : (
            <div className={styles.sectionTextHidden}>
              <div className={styles.sectionTextHiddenTitle}>
                <h2>Connectivity</h2>
                <img
                  src={Icon}
                  alt=""
                  onClick={() => handleClick("section2")}
                />
              </div>
              <p>
                Connectivity on working boats is crucial for enhancing
                communication, real-time data transmission, and remote
                monitoring, significantly improving operational efficiency and
                safety.
              </p>
              <p>
                It enables seamless coordination between the crew and onshore
                support, facilitates expert guidance from anywhere in the world,
                and allows for timely maintenance and repairs.
              </p>
              <p>
                Additionally, maintaining internet connectivity boosts crew
                welfare and morale by keeping them connected with family and
                providing access to entertainment and information, ultimately
                leading to higher job satisfaction and productivity.
              </p>
              <button className={styles.secondButton}>
                <a href="/contact">Contact us</a>
              </button>
            </div>
          )}
        </div>
        <div className={styles.section}>
          <div
            className={`${styles.sectionBackground} ${
              openSections.section3 ? styles.sectionBackgroundOverlay : ""
            }`}
            style={{ backgroundImage: `url(${Image2})` }}
          ></div>
          {openSections.section3 ? (
            <div className={styles.sectionText}>
              <p>SAFETY SOLUTIONS FOR SAFE AND SECURE OPERATIONS</p>
              <button
                className={styles.comingSoonButton}
                onClick={() => handleClick("section3")}
              >
                Read more...
              </button>
            </div>
          ) : (
            <div className={styles.sectionTextHidden}>
              <div className={styles.sectionTextHiddenTitle}>
                <h2>Safety</h2>
                <img
                  src={Icon}
                  alt=""
                  onClick={() => handleClick("section3")}
                />
              </div>
              <p>
                Safety solutions in the marine industry are invaluable as they
                significantly reduce the risk of accidents, protect human lives,
                and ensure compliance with stringent regulatory standards.
              </p>

              <p>
                Technology solutions, help in identifying and mitigating
                potential hazards before they lead to incidents. For instance,
                technologies like real-time data analytics and Computer vision
                can prevent catastrophic failures, thereby safeguarding both
                personnel and the environment.
              </p>

              <p>
                Additionally, a strong safety culture fostered by continuous
                education and adherence to best practices enhances operational
                efficiency and reduces downtime.
              </p>

              <p>
                Overall, investing in robust safety solutions not only saves
                lives and minimizes environmental impact but also enhances the
                industry's reputation and financial stability
              </p>

              <button className={styles.secondButton}>
                <a href="/contact">Contact us</a>
              </button>
            </div>
          )}
        </div>
        <div className={styles.sectionreverse}>
          <div
            className={`${styles.sectionBackground} ${
              openSections.section4 ? styles.sectionBackgroundOverlay : ""
            }`}
            style={{ backgroundImage: `url(${Image4})` }}
          ></div>
          {openSections.section4 ? (
            <div className={styles.sectionText}>
              <p>OPERATIONAL SOLUTIONS TRACK, ANALYZE & IMPROVE</p>
              <button
                className={styles.comingSoonButton}
                onClick={() => handleClick("section4")}
              >
                Read More...
              </button>
            </div>
          ) : (
            <div className={styles.sectionTextHidden}>
              <div className={styles.sectionTextHiddenTitle}>
                <h2>Operational Solutions</h2>
                <img
                  src={Icon}
                  alt=""
                  onClick={() => handleClick("section4")}
                />
              </div>
              <p>
                Managing a fleet in the oil and gas industry involves ensuring
                operational efficiency, safety, and regulatory compliance.
              </p>

              <p>
                Effective fleet management includes real-time tracking of
                vessels, optimizing routes to reduce fuel consumption, and
                implementing predictive maintenance to prevent equipment
                failures.
              </p>

              <p>
                Advanced technologies such as IoT sensors and satellite
                communication systems play a crucial role in monitoring the
                performance and condition of the fleet. These systems provide
                valuable data that can be analyzed to enhance decision-making
                and improve safety standards.
              </p>

              <p>
                Additionally, maintaining compliance with industry regulations
                and safety standards is essential to avoid costly fines and
                ensure the protection of personnel and the environment.
              </p>

              <button className={styles.secondButton}>
                <a href="/contact">Contact us</a>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SolutionsPage;
