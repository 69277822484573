// src/components/Header.tsx
import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./styles/Header.module.css";
import Logo from "../assets/Logo.svg";

const Header: React.FC = () => {
  return (
    <header className={styles.header}>
      <div className={styles.headerContainer}>
        <a href="/" className={styles.logo}>
          <img src={Logo} alt="" />
        </a>
        <nav>
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? styles.active : "")}
          >
            Home
          </NavLink>
          <NavLink
            to="/about-us"
            className={({ isActive }) => (isActive ? styles.active : "")}
          >
            About Us
          </NavLink>
          <NavLink
            to="/solutions"
            className={({ isActive }) => (isActive ? styles.active : "")}
          >
            Solutions
          </NavLink>
          <NavLink
            to="/contact"
            className={({ isActive }) => (isActive ? styles.active : "")}
          >
            Contact
          </NavLink>
        </nav>
      </div>
    </header>
  );
};

export default Header;
