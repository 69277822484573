import React from "react";
import styles from "./styles/HomePage.module.css";
import Hero from "../assets/HerobackgroundImage.png";
import EnhancedEfficiency from "../assets/ICON - Enhanced Efficiency.svg";
import CostSavings from "../assets/ICON - Cost Savings.svg";
import RealTimeData from "../assets/ICON - Real Time Data.svg";
import EnhancedSafety from "../assets/ICON - Enhanced Safety.svg";
import Compliance from "../assets/ICON - Compliance.svg";
import Image from "../assets/image.png";

import SafetyFirst from "../assets/safetyFirst.png";
import Sustainabilitymage from "../assets/sustainability.png";
import Innovative from "../assets/innovative.png";
import Excellence from "../assets/excellence.png";

const HomePage: React.FC = () => {
  return (
    <div className={styles.home}>
      <div className={styles.container}>
        <div className={styles.hero}>
          <div
            className={styles.heroBackground}
            style={{ backgroundImage: `url(${Hero})` }}
          ></div>
          <div className={styles.heroText}>
            <h1>
              Autonomous Technology for safer and lower risk marine operations.
            </h1>
          </div>
        </div>
      </div>
      <section className={styles.content}>
        <div className={styles.mainTextContent}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="80"
            viewBox="0 0 18 80"
            fill="none"
          >
            <path
              d="M9.09797 6.90027V73.0997H15.0926C15.9264 73.0997 16.602 73.3585 17.1195 73.876C17.637 74.4223 17.8958 75.0692 17.8958 75.8167V80H0.213867V0H17.8958V4.18329C17.8958 4.93082 17.637 5.57772 17.1195 6.12399C16.602 6.64151 15.9264 6.90027 15.0926 6.90027H9.09797Z"
              fill="#00A9DD"
            />
          </svg>
          <div>
            <p className={styles.mainText}>
              MARINE OPERATIONS FACE CRITICAL SAFETY AND OPERATIONAL CHALLENGES,
              RISKING LIVES AND THE ENVIRONMENT.
            </p>
            <br />
            <p className={styles.mainText}>
              CAPABILITY X TECHNOLOGY IS DESIGNED TO ACHIEVE THE FOLLOWING KEY
              BENEFITS
            </p>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="80"
            viewBox="0 0 19 80"
            fill="none"
          >
            <path
              d="M9.57098 6.90027V73.0997H3.57637C2.74259 73.0997 2.06694 73.3585 1.54942 73.876C1.0319 74.4223 0.773134 75.0692 0.773134 75.8167V80H18.4551V0H0.773134V4.18329C0.773134 4.93082 1.0319 5.57772 1.54942 6.12399C2.06694 6.64151 2.74259 6.90027 3.57637 6.90027H9.57098Z"
              fill="#00A9DD"
            />
          </svg>
        </div>
        <div className={styles.icons}>
          <div className={styles.iconItem}>
            <div className={styles.iconBackground}>
              <img src={EnhancedEfficiency} alt="" />
            </div>
            <p>ENHANCED OPERATIONAL EFFICIENCY</p>
          </div>
          <div className={styles.iconItem}>
            <div className={styles.iconBackground}>
              <img src={CostSavings} alt="" />
            </div>
            <p>COST SAVINGS AND RISK REDUCTION</p>
          </div>
          <div className={styles.iconItem}>
            <div className={styles.iconBackground}>
              <img src={RealTimeData} alt="" />
            </div>
            <p>REAL-TIME DATA AND DECISION MAKING</p>
          </div>
          <div className={styles.iconItem}>
            <div className={styles.iconBackground}>
              <img src={EnhancedSafety} alt="" />
            </div>
            <p>ENHANCED CREW SAFETY AND WELL BEING</p>
          </div>
          <div className={styles.iconItem}>
            <div className={styles.iconBackground}>
              <img src={Compliance} alt="" />
            </div>
            <p>IMPROVED COMPLIANCE & REGULATORY ADHERENCE</p>
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.bannerSection}>
            <div
              className={styles.bannerSectionImage}
              style={{
                backgroundImage: `linear-gradient(90deg, rgba(8, 13, 19, 0) 0%, #05151f 102.9%), url(${Image})`,
              }}
            ></div>
            <div className={styles.bannerContent}>
              <p>
                A leading technology and consulting firm dedicated to delivering
                innovative solutions and expert guidance to clients across
                industries. With a team of experienced professionals and a
                commitment to excellence, we help organizations navigate complex
                challenges, optimize operations, and drive growth through
                technology-driven strategies.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.valuesSection}>
          <h2>Our Values</h2>
          <div className={styles.container}>
            <div className={styles.values}>
              <div className={styles.valueItem}>
                <div
                  className={styles.valueBackground}
                  style={{ backgroundImage: `url(${SafetyFirst})` }}
                ></div>
                <p>SAFETY FIRST</p>
              </div>
              <div className={styles.valueItem}>
                <div
                  className={styles.valueBackground}
                  style={{ backgroundImage: `url(${Sustainabilitymage})` }}
                ></div>
                <p>SUSTAINABILITY</p>
              </div>
              <div className={styles.valueItem}>
                <div
                  className={styles.valueBackground}
                  style={{ backgroundImage: `url(${Innovative})` }}
                ></div>
                <p>INNOVATIVE</p>
              </div>
              <div className={styles.valueItem}>
                <div
                  className={styles.valueBackground}
                  style={{ backgroundImage: `url(${Excellence})` }}
                ></div>
                <p>EXCELLENCE</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
