import React from "react";
import styles from "./styles/AboutUsPage.module.css";
import AboutHero from "../assets/AboutHero.png";
import BackgroundDecoration from "../assets/BackgroundDecoration.png";

const AboutUsPage: React.FC = () => {
  return (
    <div className={styles.about}>
      <div className={styles.container}>
        <div className={styles.hero}>
          <div
            className={styles.heroBackground}
            style={{ backgroundImage: `url(${AboutHero})` }}
          ></div>
        </div>
      </div>
      <div
        className={styles.backgroundDecoration}
        style={{ backgroundImage: `url(${BackgroundDecoration})` }}
      >
        <section className={styles.content}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="80"
            viewBox="0 0 18 80"
            fill="none"
          >
            <path
              d="M9.09797 6.90027V73.0997H15.0926C15.9264 73.0997 16.602 73.3585 17.1195 73.876C17.637 74.4223 17.8958 75.0692 17.8958 75.8167V80H0.213867V0H17.8958V4.18329C17.8958 4.93082 17.637 5.57772 17.1195 6.12399C16.602 6.64151 15.9264 6.90027 15.0926 6.90027H9.09797Z"
              fill="#00A9DD"
            />
          </svg>
          <div>
            <p>
              At CapabilityX, our mission is to revolutionize maritime safety
              through innovative autonomous technology.
            </p>
            <p>
              We are committed to ensuring that every voyage is{" "}
              <span>secure</span>,<span>efficient</span>, and{" "}
              <span>environmentally responsible.</span>
            </p>
            <p>
              By integrating cutting-edge solutions, we aim to
              <span>protect lives</span>,{" "}
              <span>preserve marine environments</span>, and set{" "}
              <span>new standards</span> for the future of boating and maritime
              operations.
            </p>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="80"
            viewBox="0 0 19 80"
            fill="none"
          >
            <path
              d="M9.57098 6.90027V73.0997H3.57637C2.74259 73.0997 2.06694 73.3585 1.54942 73.876C1.0319 74.4223 0.773134 75.0692 0.773134 75.8167V80H18.4551V0H0.773134V4.18329C0.773134 4.93082 1.0319 5.57772 1.54942 6.12399C2.06694 6.64151 2.74259 6.90027 3.57637 6.90027H9.57098Z"
              fill="#00A9DD"
            />
          </svg>
        </section>
      </div>
    </div>
  );
};

export default AboutUsPage;
