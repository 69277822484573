import React from "react";
import styles from "./styles/Footer.module.css";
import Linkedin from "../assets/linkedin.svg";

const Footer: React.FC = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className={styles.column}>
          <a
            href="https://www.linkedin.com/company/capabilitx/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Linkedin} alt="LinkedIn" className={styles.icon} />
            Connect with us at Linkedin
          </a>
        </div>
        <div className={styles.column}>
          <p>Copyright © 2024 - All rights reserved.</p>
        </div>
        <div className={styles.column}>
          <p>620, Gnangara Rd - Cullacabardee - WA6067 - 61 429-202-090</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
