import React, { useState } from "react";
import styles from "./styles/ContactPage.module.css";
import ContactHero from "../assets/ContactHero.png";
import BackgroundDecoration from "../assets/BackgroundDecoration.png";

const ContactPage: React.FC = () => {
  const [result, setResult] = useState("");
  const [success, setSuccess] = useState(false);

  const onSubmit = async (event: any) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "7a2920b7-7788-495a-bf6e-876fcffc23ae");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      setSuccess(true);
      event.target.reset();
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    } else {
      console.log("Error", data);
      setResult(data.message);
      setSuccess(false);
    }
  };

  return (
    <div className={styles.contact}>
      <div className={styles.container}>
        <div className={styles.hero}>
          <div
            className={styles.heroBackground}
            style={{ backgroundImage: `url(${ContactHero})` }}
          ></div>
        </div>
      </div>
      <div
        className={styles.backgroundDecoration}
        style={{ backgroundImage: `url(${BackgroundDecoration})` }}
      >
        <p>
          Thank you for your interest in Capability X. We look forward to
          connecting with you.
        </p>
        <section className={styles.content}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="80"
            viewBox="0 0 18 80"
            fill="none"
          >
            <path
              d="M9.09797 6.90027V73.0997H15.0926C15.9264 73.0997 16.602 73.3585 17.1195 73.876C17.637 74.4223 17.8958 75.0692 17.8958 75.8167V80H0.213867V0H17.8958V4.18329C17.8958 4.93082 17.637 5.57772 17.1195 6.12399C16.602 6.64151 15.9264 6.90027 15.0926 6.90027H9.09797Z"
              fill="#00A9DD"
            />
          </svg>

          {!success ? (
            <form onSubmit={onSubmit}>
              <div className={styles.col}>
                <div className={styles.input}>
                  <label htmlFor="firstName">First name*</label>
                  <input type="text" name="firstName" required />
                </div>
                <div className={styles.input}>
                  <label htmlFor="lastName">Last name*</label>
                  <input type="text" name="lastName" required />
                </div>
              </div>
              <div className={styles.input}>
                <label htmlFor="businessEmail">Business e-mail*</label>
                <input type="email" name="businessEmail" required />
              </div>
              <div className={styles.input}>
                <label htmlFor="phoneNumber">Phone number*</label>
                <input type="tel" name="phoneNumber" required />
              </div>
              <div className={styles.input}>
                <label htmlFor="details">Enter details of your enquiry*</label>
                <textarea name="details" required></textarea>
              </div>
              <button className={styles.secondButton} type="submit">
                Submit
              </button>
            </form>
          ) : (
            <div className={styles.response}>
              <p>
                We appreciate you reaching out to us. Your message has been
                successfully received, and our team will be in contact shortly.
                In the meantime, feel free to browse our website for more
                information.
              </p>
            </div>
          )}

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="80"
            viewBox="0 0 19 80"
            fill="none"
          >
            <path
              d="M9.57098 6.90027V73.0997H3.57637C2.74259 73.0997 2.06694 73.3585 1.54942 73.876C1.0319 74.4223 0.773134 75.0692 0.773134 75.8167V80H18.4551V0H0.773134V4.18329C0.773134 4.93082 1.0319 5.57772 1.54942 6.12399C2.06694 6.64151 2.74259 6.90027 3.57637 6.90027H9.57098Z"
              fill="#00A9DD"
            />
          </svg>
        </section>
      </div>
    </div>
  );
};

export default ContactPage;
